import api from './api';
import {AppResponse} from "../models/common/app-response";
import { CompanyShortDto } from '../models/companies/companyShortDto';
import { DepartmentShortDto } from '../models/departments/departmentShortDto';
import { PaginatedResponse } from '../models/common/paginated-response';
import { CompanyFilter } from '../models/companies/companyFilter';
import { CompanyTableViewDto } from '../models/companies/companyTableViewDto';
import { AddCompanyDto } from '../models/companies/addCompanyDto';
import { AddDepartmentDto } from '../models/departments/addDepartmentDto';
import { SearchCompanyFilter } from '../models/companies/searchCompanyFilter';
import qs from 'qs';

class CompaniesService {

    baseUrl: string = "/api/v1/companies";

    getByFilter = (filter?: CompanyFilter) => {
        return api.get<AppResponse<PaginatedResponse<CompanyTableViewDto>>>(this.baseUrl, { params: filter });
    }

    search = (filter?: SearchCompanyFilter) => {
        return api.get<AppResponse<PaginatedResponse<CompanyShortDto>>>(`${this.baseUrl}/search`, {
            params: filter,
            paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
        });
    }

    getDepartments = (companyId: number) => {
        return api.get<AppResponse<DepartmentShortDto[]>>(`${this.baseUrl}/${companyId}/departments`);
    }

    getDepartmentsByCompanyIds = (companyIds: number[]) => {
        return api.post<AppResponse<DepartmentShortDto[]>>(`${this.baseUrl}/departments`, companyIds);
    }

    add = (data: AddCompanyDto) => {
        return api.post<AppResponse<number>>(this.baseUrl, data);
    }
    
    addDepartment = (companyId: number, data: AddDepartmentDto) => {
        return api.post<AppResponse<number>>(`${this.baseUrl}/${companyId}/department`, data);
    }
}

export const companiesService = new CompaniesService();