import { CompanyShortDto } from "../companies/companyShortDto";
import { DepartmentShortDto } from "../departments/departmentShortDto";
import { EditHealthCertificateFormDto, mapToEditHealthCertificateFormDto } from "../healthCertificates/editHealthCertificateFormDto";
import { DefaultIndicatorsDto, emptyIndicators } from "../medicalIndicators/defaultIndicatorsDto";
import { UserShortDto, emptyUser } from "../users/userShortDto";
import { DriverDto } from "./driverDto";

/**
 * Interface representing a driver create/edit form.
 */
export interface EditDriverFormDto {
  id: number | null;
  user: UserShortDto;
  employeeNumber: string;
  isDismissed: boolean;
  snils: string | '';
  licenceSerial: string | '';
  licenceNumber: string | '';
  licenceIssued: Date | null;
  passportSerial: string | '';
  passportNumber: string | '';
  defaultIndicators: DefaultIndicatorsDto;
  companyId: number | null;
  departmentIds: number[];
  departments: DepartmentShortDto[];
  healthCertificates: EditHealthCertificateFormDto[];
}

export const emptyEditDriverFormDto = (companyId?: number): EditDriverFormDto => {
  return {
  id: null,
  user: emptyUser,
  employeeNumber: '',
  isDismissed: false,
  snils: '',
  licenceSerial: '',
  licenceNumber: '',
  licenceIssued: null,
  passportSerial: '',
  passportNumber: '',
  defaultIndicators: emptyIndicators,
  companyId: companyId ? companyId : null,
  departmentIds: [],
  departments: [],
  healthCertificates: [],
  }
};

export const mapToEditDriverFormDto = (driverDto: DriverDto): EditDriverFormDto => {
  return {
        id: driverDto.id,
        user: driverDto.user,
        employeeNumber: driverDto.employeeNumber,
        isDismissed: driverDto.isDismissed,
        snils: driverDto.snils,
        licenceSerial: driverDto.licenceSerial,
        licenceNumber: driverDto.licenceNumber,
        licenceIssued: driverDto.licenceIssued,
        passportSerial: driverDto.passportSerial,
        passportNumber: driverDto.passportNumber,
        defaultIndicators: driverDto.defaultIndicators,
        companyId: driverDto.company?.id ?? null,
        departmentIds: driverDto.departments.map(d => d.id),
        departments: driverDto.departments,
        healthCertificates: driverDto.healthCertificates.map(mapToEditHealthCertificateFormDto)
  }
};