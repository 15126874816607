export const ROUTES = {
    auth: {
        login: '/login',
        notFound: '/not-found',
        register: '/register',
        setPassword: '/set-password',
        passwordRecovery: '/password-recovery',
    },
    clients: {
        list: '/clients',
        idPath: '/clients/:id',
        add: '/clients/add'
    },
    drivers: {
        list: '/drivers',
        idPath: '/drivers/:id'
    },
    medics: {
        list: '/medics',
        idPath: '/medics/:id'
    },
    medicalCheckups: {
        listOfNew: '/medical-checkups/new',
        history: '/medical-checkups/history',
        journal: '/medical-checkups/journal',
        idPath: '/medical-checkups/:id'
    },
    terminals: {
        list: '/terminals',
        idPath: '/terminals/:id'
    },
    dispatchers: {
        list: '/dispatchers',
        idPath: '/dispatchers/:id'
    },
};