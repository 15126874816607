import React, { useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, Grid } from '@mui/material';
import { useForm, FormProvider, FieldErrors } from 'react-hook-form';
import TmFullButton from '../../../common/buttons/TmFullButton';
import { TextFieldFormControl } from '../../../common/form/TextFieldFormControl';
import TmEmptyButton from '../../../common/buttons/TmEmptyButton';
import { companiesService } from '../../../../services/companiesService';
import { Department } from '../models/department';

interface AddNewDepartmentModalProps {
    open: boolean;
    companyId: number;
    onClose: () => void;
    onSave: (data: Department) => void;
}

interface FormValues {
    name: string;
    address: string;
}

export const AddNewDepartmentModal: React.FC<AddNewDepartmentModalProps> = ({ open, companyId, onClose, onSave }) => {
    const methods = useForm<FormValues>({
        defaultValues: {
            name: '',
            address: '',
        }
    });
    const { reset } = methods;

    useEffect(() => {
        if (open) {
            reset({ name: '', address: '' });
        }
    }, [open, reset]);

    const onSubmit = (data: FormValues) => {
        companiesService.addDepartment(companyId, data)
            .then(r => {
                onClose();
                const added = {
                    id: r.data.data,
                    name: data.name,
                    address: data.address,
                    dispatchers: []
                };
                onSave(added);
            })
            .catch(e => console.log(e));
    };

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ sx: { borderRadius: 2, width: 400 } }}>
            <DialogTitle variant="h6" component="h2" textAlign="center">
                Создание объекта
            </DialogTitle>
            <DialogContent>
                <FormProvider {...methods}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldFormControl
                                name="name"
                                label="Название"
                                required
                                maxLength={50}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldFormControl
                                name="address"
                                label="Адрес"
                                required
                                maxLength={50}
                            />
                        </Grid>
                    </Grid>
                </FormProvider>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', mt: 3, mx: 2, mb: 2 }}>
                <TmEmptyButton onClick={onClose}>
                    Отмена
                </TmEmptyButton>
                <TmFullButton onClick={methods.handleSubmit(onSubmit)}>
                    Сохранить
                </TmFullButton>
            </DialogActions>
        </Dialog>
    );
};