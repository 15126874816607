import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography, IconButton, Box, Avatar, Modal } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Spinner from '../../common/Spinner';
import { ROUTES } from '../../../constants/routes';
import { dispatchersService } from '../../../services/dispatchersService';
import { EditDispatcherModal } from '../edit/EditDispatcherModal';
import AcceptLabel from '../../common/labels/AcceptLabel';
import DenyLabel from '../../common/labels/DenyLabel';
import TmPencil from '../../../icons/TmPencil';
import { CategoryType } from '../../../models/enums/categoryType';
import { PermissionType } from '../../../models/enums/permissionType';
import { ShowForPermission } from '../../common/permissions/ShowForPermission';
import { DispatcherDetailsDto } from '../../../models/dispatchers/dispatcherDetailsDto';
import { Gender, getGenderString } from '../../../models/enums/gender';
import maleAvatar from '../../../assets/images/male-avatar.png';
import femaleAvatar from '../../../assets/images/female-avatar.png';
import { CompanyShortWithDepartmentsDto } from '../../../models/companies/companyShortWithDepartmentsDto';


export const DispatcherDetails: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [dispatcher, setDispatcher] = useState<DispatcherDetailsDto>();
  const [isEditPopupOpen, setEditPopupOpen] = useState(false);

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("false");

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const dispatcherId = Number(params.id);

    dispatchersService.getById(dispatcherId)
      .then(r => setDispatcher(r.data.data))
      .catch(e => {
        console.error(e);
        if (e.response.status === 404) {
          navigate(ROUTES.auth.notFound);
        }
      })
      .finally(() => setLoading(false));
  }, [params.id]);

  const refreshData = () => {
    if (!params.id) return;

    const dispatcherId = Number(params.id);
    setLoading(true);

    dispatchersService.getById(dispatcherId)
      .then(r => setDispatcher(r.data.data))
      .catch(e => console.error(e))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setImageUrl(dispatcher?.photoUrl || (dispatcher?.gender === Gender.Female ? femaleAvatar : maleAvatar));
  }, [dispatcher]);

  function handleEditPopupToggle(): void {
    setEditPopupOpen(!isEditPopupOpen);
  }

  const handleImage = (value: any) => {
    setImageUrl(value);
    setImageModalOpen(true);
  };

  const renderCompany = (company: CompanyShortWithDepartmentsDto) => {
    return (
      <Box key={company.id} className="gbr" sx={{ ml: 2, mr: 2, mb: 1, padding: 1 }}>
        <Typography variant="body1" px={2} className="grey">Клиент</Typography>
        <Box className="gbr" py={1} px={2} mt={1} ml={2} mb={2} sx={{ display: 'inline-block' }}>
          <Typography variant="body2" fontWeight="bold">{company?.name}</Typography>
        </Box>

        {company.departments && company.departments.length > 0 ? (
          <>
            <Typography px={2} variant="body1" sx={{ marginBottom: 1 }} className="grey">Объекты клиента</Typography>
            <Grid container spacing={2} paddingLeft={2} paddingBottom={1}>
              {company.departments.map((department) => (
                <Box key={department.id} className="gbr" py={1} px={2} mt={2} ml={2}>
                  <Typography variant="body2" fontWeight="bold">{department.name}</Typography>
                </Box>
              ))}
            </Grid>
          </>
        ) : (
          <Typography variant="body2" className="grey" px={2}>Нет отделов</Typography>
        )}
      </Box>
    );
  };

  return (
    <Box>
      {loading
        ? <Spinner />
        : (<Box>

          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={2}>
              <IconButton onClick={() => navigate(-1)} sx={{ my: 2 }}>
                <Avatar sx={{ backgroundColor: "white", border: 1, borderColor: "#9588e8" }}>
                  <ChevronLeftIcon sx={{ color: '#9588e8' }} />
                </Avatar>
              </IconButton>
            </Grid>
            <Grid item xs={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h6" align="center">Данные диспетчера</Typography>
            </Grid>
            <Grid item xs={2} /> {/* Empty Grid item for balancing */}
          </Grid>

          <Grid container px={3} pt={1} pb={1} mt={1} className="gbr">
            <Grid item md={3} xs={12}>
              <Typography className="ltb">Должность</Typography>
              &nbsp; <Typography className="rtb">Диспетчер</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
              <Typography className="ltb">Статус:&nbsp;</Typography>
              <Typography className="rtb">{dispatcher?.isDismissed ? <DenyLabel>Уволен</DenyLabel> : <AcceptLabel>Работает</AcceptLabel>}</Typography>
            </Grid>
            <Grid item md={3} xs={12}>
            </Grid>
            <Grid item md={3} xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ShowForPermission catalog={CategoryType.Dispatchers} permission={PermissionType.Edit}>
                <IconButton className="list-item" sx={{ px: "20px" }} onClick={handleEditPopupToggle}>
                  <TmPencil fill="none" stroke="white" />&nbsp;&nbsp;
                  <Typography fontWeight="bold">
                    Редактировать
                  </Typography>
                </IconButton>
              </ShowForPermission>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={0.5}>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box className="gbr" px={2} pb={2} sx={{ flex: 1 }}>
                  <Typography pt={2} pb={1} variant="h5" fontWeight="bold">
                    Личные данные
                  </Typography>
                  <Box>
                    <Typography className="ltb" variant="body1">ФИО</Typography>
                    &nbsp; <Typography className="rtb">{dispatcher?.lastName} {dispatcher?.firstName} {dispatcher?.middleName}</Typography>
                  </Box>
                  <Box>
                    <Typography className="ltb" variant="body1">Пол</Typography>
                    &nbsp; <Typography className="rtb">{dispatcher ? getGenderString(dispatcher.gender) : '_'}</Typography>
                  </Box>
                  <Box>
                    <Typography className="ltb" variant="body1">Телефон</Typography>
                    &nbsp; <Typography className="rtb">{dispatcher?.phoneNumber}</Typography>
                  </Box>
                  <Box>
                    <Typography className="ltb" variant="body1">Email</Typography>
                    &nbsp; <Typography className="rtb">{dispatcher?.email}</Typography>
                  </Box>
                  <Box>
                    <Typography className="ltb" variant="body1">События для уведомления</Typography>
                    &nbsp; <Typography className="rtb">Почта</Typography>
                  </Box>
                  <Box>
                    <Typography className="ltb" variant="body1">Способы получения</Typography>
                    &nbsp; <Typography className="rtb">
                      {dispatcher?.notificationTypes && dispatcher.notificationTypes.length > 0
                        ? dispatcher?.notificationTypes.map(d => d.name).join(', ')
                        : '<Отсутствует>'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5" pt={2} pb={1} sx={{ fontWeight: 'bold' }}>
                      Фото диспетчера
                    </Typography>
                    {(imageUrl)
                      ? (
                        <img
                          src={imageUrl}
                          alt='Фото водителя'
                          onClick={(e) => handleImage(imageUrl)}
                          style={{ width: '30%', maxWidth: "30%", borderRadius: "25px" }}
                        />
                      )
                      : (<Avatar
                        alt="Фото диспетчера"
                        src={(dispatcher?.gender === Gender.Male ? maleAvatar : femaleAvatar)}
                        sx={{ width: 100, height: 100 }}
                      />)}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box className="gbr" pb={2.5} sx={{ flex: 1 }}>
                  <Typography px={2} pt={2} variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                    Клиенты и объекты
                  </Typography>
                  {dispatcher?.companies && dispatcher.companies.length > 0
                    ? dispatcher.companies.map((company) => renderCompany(company))
                    : <Typography px={2} variant="body2" className="grey">Нет компаний</Typography>
                  }
                </Box>
              </Grid>

            </Grid >
          </Grid >

          <EditDispatcherModal onClose={handleEditPopupToggle} onRefresh={refreshData} isAdding={false} dispatcher={dispatcher} open={isEditPopupOpen} />
        </Box >)}
      <Modal
        className='modal-img'
        open={imageModalOpen}
        onClose={() => setImageModalOpen(false)}
        closeAfterTransition
      >
        <img
          src={imageUrl}
          alt='Фото диспетчера'
          style={{ maxHeight: "90%", maxWidth: "90%" }}
        />
      </Modal>
    </Box >
  );
};
