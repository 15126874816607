import React, { useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Grid, IconButton } from '@mui/material';
import TmFullButton from '../../../common/buttons/TmFullButton';
import TmPlus from '../../../../icons/TmPlus';
import { ShowForPermission } from '../../../common/permissions/ShowForPermission';
import { CategoryType } from '../../../../models/enums/categoryType';
import { PermissionType } from '../../../../models/enums/permissionType';
import TmDownload from '../../../../icons/TmDownload';
import TmEmptyButton from '../../../common/buttons/TmEmptyButton';
import { EditDriverModal } from '../../../drivers/edit/EditDriverModal';
import { ImportDriversDialog } from '../../../drivers/edit/ImportDriversDialog';
import { driversService } from '../../../../services/driversService';
import { DriverDto } from '../../../../models/drivers/driverDto';
import { useAddCompanyContext } from '../context/AddCompanyContextProvider';
import { Driver } from '../models/driver';

interface AddDriversTabProps {
    onNext: () => void;
    onPrevious: () => void;
}

export const AddDriversTab: React.FC<AddDriversTabProps> = ({ onNext, onPrevious }) => {
    const { companyId, departments, drivers, setDrivers, setLoading, setError } = useAddCompanyContext();
    const [isCreateDriverModalOpen, setCreateDriverModalOpen] = useState(false);
    const [isImportDriversModalOpen, setImportDriversModalOpen] = useState(false);

    const handleNewDriversAdded = (newDriverIds: number[]): void => {
        setLoading(true);
        driversService
            .getByIds(newDriverIds)
            .then(r => {
                const newDrivers = r.data.data.map(mapDriverDtoToDriver);
                setDrivers(prevDrivers => [
                    ...prevDrivers,
                    ...newDrivers
                ]);
            })
            .catch(e => {
                console.error('Error fetching drivers:', e);
                setError('Ошибка при добавлении водителей');
            })
            .finally(() => setLoading(false));
    };

    const mapDriverDtoToDriver = (dto: DriverDto): Driver => {
        return {
            id: dto.id!,
            fullName: `${dto.user.lastName} ${dto.user.firstName} ${dto.user.middleName}`,
            departments: dto.departments.map(d => ({ id: d.id, name: d.name })),
        };
    };

    useEffect(() => {
        // Initialize drivers from context if any are present
        setDrivers(drivers);
    }, [drivers, setDrivers]);

    return (
        <Box sx={{ textAlign: 'center', width: '100%', mx: 'auto' }}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12} md={4}>
                    <Typography variant="h6" sx={{ textAlign: 'left', mb: 2 }}>
                        Водители
                    </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                        <ShowForPermission catalog={CategoryType.Companies} permission={PermissionType.Add}>
                            <IconButton sx={{ display: "flex" }} className="list-item-outline" onClick={() => setImportDriversModalOpen(true)}>
                                <TmDownload fill="none" stroke="#9588e8" />&nbsp;
                                <Typography fontWeight="bold" pr={1}>
                                    &nbsp;Импорт
                                </Typography>
                            </IconButton>
                            <IconButton sx={{ display: "flex" }} className="list-item" onClick={() => setCreateDriverModalOpen(true)}>
                                <TmPlus fill="none" stroke="white" />&nbsp;
                                <Typography fontWeight="bold" pr={1}>
                                    &nbsp;Создать нового водителя
                                </Typography>
                            </IconButton>
                        </ShowForPermission>
                    </Box>
                </Grid>
            </Grid>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ФИО</TableCell>
                            <TableCell>Объекты</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {drivers.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    <Typography sx={{ color: '#999999', mt: 3, mb: 3, textAlign: 'center' }} component={'span'}>
                                        У вас пока не добавлен ни один водитель
                                        <br />
                                        <Typography sx={{ fontSize: '0.85rem', textAlign: 'center' }}>
                                            Необходимо добавить хотя бы 1 водителя, чтобы продолжить добавление компании
                                        </Typography>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            drivers.map((driver) => (
                                <TableRow key={driver.id}>
                                    <TableCell sx={{ fontWeight: "bold" }}>{driver.fullName}</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }}>{driver.departments.map(d => d.name).join(', ')}</TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, gap: 3 }}>
                <TmEmptyButton width="25%" onClick={onPrevious}>
                    Назад
                </TmEmptyButton>
                <TmFullButton width="25%" onClick={onNext} disabled={drivers.length === 0}>
                    Далее
                </TmFullButton>
            </Box>

            <EditDriverModal
                open={isCreateDriverModalOpen}
                onClose={() => setCreateDriverModalOpen(false)}
                isAdding={true}
                companyId={companyId!}
                onAddingSuccess={(id) => handleNewDriversAdded([id])}
            />

            <ImportDriversDialog
                open={isImportDriversModalOpen}
                onClose={() => setImportDriversModalOpen(false)}
                companyId={companyId!}
                onSuccess={handleNewDriversAdded}
            />
        </Box>
    );
};
